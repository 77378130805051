import React from 'react';

import Pitch from '../Icons/pitch3.png';

import { 
	Image,
    Container,
    Flex,
    Box,
    Text
} from '@chakra-ui/react';


const LineUp = (props) => {
    const positions = ['Torwart', 'Abwehr', 'Mittelfeld', 'Sturm'];
    return props.MatchId ? (
        <>
            <Image src={Pitch}/>
            <Container maxW='2xl' position='absolute' top='5%' left='50%' transform="translateX(-50%)">
                    {positions
                        .map(positionKey => {
                            return(
                                <>
                                <Flex justifyContent='center' gap={15} textAlign='center'>
                                {props.TeamList
                                    .filter(d => props.HomeTeam === d.team)
                                    .filter(a => positionKey === a.position)
                                    .map(playerKey => {
                                        let imgUrl
                                        if (props.safeMode === 1){
                                            imgUrl = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
                                        } else {
                                            playerKey.image ? imgUrl = playerKey.image : imgUrl = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
                                        }
                                        return(
                                            <>
                                                <Box w='25%'>
                                                    <Box paddingBottom='100%' position='relative' overflow='hidden' borderRadius='100%'>
                                                        <Image 
                                                            src={imgUrl}
                                                            alt={playerKey.player_name}
                                                            position='absolute'/>   
                                                    </Box>
                                                    
                                                    <Text
                                                        color='white'
                                                        paddingBottom={1}>{playerKey.player_name}</Text>
                                                </Box>
                                            </>
                                        )
                                    })
                                }
                                </Flex>
                                </>
                            )
                        })
                    }
                    {positions
                        .reverse()
                        .map(positionKey => {
                            return(
                                <>
                                <Flex justifyContent='center' gap={15} textAlign='center'>
                                {props.TeamList
                                    .filter(d => props.AwayTeam === d.team)
                                    .filter(a => positionKey === a.position)
                                    .map(playerKey => {
                                        let imgUrl
                                        if (props.safeMode === 1){
                                            imgUrl = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
                                        } else {
                                            playerKey.image ? imgUrl = playerKey.image : imgUrl = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
                                        }
                                        return(
                                            <>
                                                <Box w='25%'>
                                                    <Box paddingBottom='100%' position='relative' overflow='hidden' borderRadius='100%'>
                                                        <Image 
                                                            src={imgUrl}
                                                            alt={playerKey.player_name}
                                                            position='absolute'/>   
                                                    </Box>
                                                    
                                                    <Text
                                                        color='white'
                                                        paddingBottom={1}>{playerKey.player_name}</Text>
                                                </Box>
                                            </>
                                        )
                                    })
                                }
                                </Flex>
                                </>
                            )
                        })
                    }
            </Container>
        </>
    ) : (
        <>
        </>
    )
};

export default LineUp