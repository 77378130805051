import React from 'react';
import { Link } from 'react-router-dom';

import { Grid,
	GridItem,
	Heading,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Text,
	Divider,
	Container,
	Box
} from '@chakra-ui/react'

const TeamStats = (props) => {
	
	const [data] = React.useState({ 
		seasonId: '',
		players: [],
		stats: [
			{
				type: 'goals',
				title: 'Top Goalscorer',
                shortTitle : 'Goals'
			},
			{
				type: 'assists',
				title: 'Most Assists',
                shortTitle : 'Assists'
			},
			{
				type: 'yc',
				title: 'Yellow Cards',
                shortTitle : 'Yellows'
			},
			{
				type: 'rc',
				title: 'Red Cards',
                shortTitle : 'Reds'
			}
		]
	});



	const displayData = () => {
	    return props.Players ? (
	      	<>
	      		<Grid
					templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', '2xl':'repeat(6, 1fr)' }}
					gap={6}
					bg='blackAlpha.900'
					padding={10}
				>
					{data.stats
						.map((dataKey, i) =>{
						return (
							<>
								<GridItem
									colSpan={{base: 1, md: 2, '2xl':6 }}
									w='100%'
									textAlign='center'
									color='white'
									textTransform='uppercase'
									key={dataKey.title}
								>
									<Heading>{ dataKey.title }</Heading>
								</GridItem>
								{props.Players
                                    .sort((player1, player2) => (parseInt(player1[dataKey.type]) > parseInt(player2[dataKey.type])) ? -1 : 1)
									.slice(0, 6)
									.map((playerKey, i) =>{
										let stats = dataKey.type
										let img = ''
										let img2 = ''
										let imgHover = ''
										if (props.safeMode === 1){
											img = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											img2 = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											imgHover = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
										} else {
											img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
										}
										return(
											<GridItem key={i}
												w='100%'>
												<Link to={'/player/' + playerKey.id}>
													<Card 
														bgImage={img}
														bgSize="cover"
														bgPos='center'
														minH="550px"
														_hover={{bgImage:imgHover }}
														>
														<CardHeader
															bg='transparent'
														>
															<Text
																fontSize="7xl"
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																lineHeight={1}
															>
																{playerKey[stats]}
															</Text>
															<Text
																lineHeight={1}
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																textTransform="uppercase"
															>
																{dataKey.shortTitle}
															</Text>
														</CardHeader>
														<CardBody
															display="flex"
															alignItems="flex-end"
														>
														<Text
																fontSize="3xl"
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																lineHeight={1}
																textTransform="uppercase"
															>
																{playerKey.firstname} {playerKey.surname}
															</Text>
														</CardBody>
														<Divider />
														<CardFooter>
															<Text
																lineHeight={1}
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																textTransform="uppercase"
															>
																Player Profile
															</Text>
														</CardFooter>
													</Card>
												</Link>
											</GridItem>
										)
									})
								}
							</>
						)
					})}
				</Grid>
	        </>
	    ) : (
	    	<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Box boxShadow='md' bg='white' w='100%' p={20}>
					<Heading size="2xl" textTransform="uppercase">No Stats Found</Heading>
				</Box>
			</Container>
	    );
	 }

	return(
		<>
			{displayData()}
        </>
	)
}

export default TeamStats;