import React from 'react';
import { Link } from 'react-router-dom';

import {
	Container,
	Heading,
	Grid,
	GridItem,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Text,
	Divider,
	Box,
	Badge,
	Avatar
} from '@chakra-ui/react'


const TeamList = (props) => {

	const [post, setPost] = React.useState({players : props.Players, position : null});

	const playerList = () => {

		const positions = ["Torwart", "Abwehr", "Mittelfeld", "Sturm"]

		return post.players ? (
			<>
				{positions.map((positionKey, i) => {
					let positionTranslation = ''
					if (positionKey == "Torwart"){
						positionTranslation = 'Goalkeeper'
					} else if (positionKey == "Abwehr"){
						positionTranslation = 'Defender'
					} else if (positionKey == "Mittelfeld"){
						positionTranslation = 'Midfielder'
					} else if (positionKey == "Sturm"){
						positionTranslation = 'Striker'
					}
					return(
						<>
							<Heading
								size='xl'
								textTransform='uppercase'
								pt={10}
								pb={5}
								>
								{ positionTranslation }
							</Heading>
							<Grid
					templateColumns={{ sm:'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
					gap={6}
				>
					{post.players
				.filter(playerKey => playerKey.position === positionKey )
				.sort(function (player1, player2) {
					if (player1.mainPosition < player2.mainPosition) return -1;
					if (player1.mainPosition > player2.mainPosition) return 1;
					if (player1.surname < player2.surname) return -1;
					if (player1.surname > player2.surname) return 1;
				})
				.map((playerKey, i) => {
					let Suspension = null
					let Injured = playerKey.injured > 0 ? <span className="injured"><span>{playerKey.injured}</span></span> : null;
					let PositionTranslate = playerKey.position
					let MainPositionTranslate = playerKey.mainPosition
					let img = ''
					let img2 = ''
					let imgHover = ''
					if (props.safeMode == 1){
						img = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						img2 = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						imgHover = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
					} else {
						img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
						imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
					}

					if (playerKey.mainPosition === "T"){
						MainPositionTranslate = "Goalkeeper"
					} else if (playerKey.mainPosition === "LV"){
						MainPositionTranslate = "Left Back"
					} else if (playerKey.mainPosition === "IV"){
						MainPositionTranslate = "Centre Back"
					} else if (playerKey.mainPosition === "RV"){
						MainPositionTranslate = "Right Back"
					} else if (playerKey.mainPosition === "LM"){
						MainPositionTranslate = "Left Midfield"
					} else if (playerKey.mainPosition === "DM"){
						MainPositionTranslate = "Defensive Midfield"
					} else if (playerKey.mainPosition === "ZM"){
						MainPositionTranslate = "Centre Midfield"
					} else if (playerKey.mainPosition === "RM"){
						MainPositionTranslate = "Right Midfield"
					} else if (playerKey.mainPosition === "OM"){
						MainPositionTranslate = "Attacking Midfield"
					} else if (playerKey.mainPosition === "LS"){
						MainPositionTranslate = "Left Forward"
					} else if (playerKey.mainPosition === "MS"){
						MainPositionTranslate = "Striker"
					} else if (playerKey.mainPosition === "RS"){
						MainPositionTranslate = "Right Forward"
					} else if (playerKey.mainPosition === ""){
						MainPositionTranslate = PositionTranslate
					}

					if (playerKey.suspension > "0"){
						Suspension = <Badge variant='solid' colorScheme='red' fontSize='0.8em'>Suspended - {playerKey.suspension} Games</Badge>
					} else if (playerKey.suspension_cups > "0"){
						Suspension = <Badge variant='solid' colorScheme='red' fontSize='0.8em'>Suspended - {playerKey.suspension_cups} Cup Games</Badge>
					} else if ((playerKey.suspension > "0") && (playerKey.suspension_cups > "0")){
						Suspension = <Badge variant='solid' colorScheme='red' fontSize='0.8em'>Suspended - {playerKey.suspension} Games /{playerKey.suspension_cups} Cup Games</Badge>
					}
					return (
							<>
								<GridItem key={i}
									w='100%'>
									<Link to={'/player/' + playerKey.playerId}>
										<Card 
											bgImage={img}
											bgSize="cover"
											bgPos='center'
											minH="550px"
											_hover={{ bgImage:imgHover }}
											>
											<CardHeader
												bg='transparent'
											>
												<Avatar 
													size='xs'
													src={'https://websoccer.blue-phoenix.co.uk/img/flags/players/' + playerKey.nation + '.png'}
													name={playerKey.nation}
													mr={2} />
												{playerKey.transfer_listed == '1' ? <Badge variant='solid' colorScheme='blue' fontSize='0.8em'>Transfer Listed</Badge> : null}
												{Suspension}
											</CardHeader>
											<CardBody
												display="flex"
												alignItems="flex-end"
												flexWrap="wrap"
											>
												<Box>
													<Text
														fontSize="3xl"
														bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
														bgClip='text'
														lineHeight={1}
														textTransform="uppercase"
													>
														{playerKey.firstname} {playerKey.surname}
													</Text>
													<Text
														fontSize="xl"
														bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
														bgClip='text'
														lineHeight={1}
														textTransform="uppercase"
													>
														{MainPositionTranslate}
													</Text>
												</Box>
											</CardBody>
											<Divider />
											<CardFooter>
												<Text
													lineHeight={1}
													bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
													bgClip='text'
													textTransform="uppercase"
												>
													Player Profile
												</Text>
											</CardFooter>
										</Card>
									</Link>
								</GridItem>
							</>
							)
						})
					}
				</Grid>
						</>
					)
				})

				}
			</>
		) : (
			<>
				<h2 className="text-center"> No Players Found</h2>
			</>
		)
	}

	const displayData = () => {
		return props.Players ? (
			<>
				{playerList()}
			</>
		) : (
			<>
				<Container
						maxW='container.3xl'
						pt='75px;'
						pb='75px;'
					>
					<Box boxShadow='md' bg='white' w='100%' p={20}>
						<Heading size="2xl" textTransform="uppercase">No Players Found</Heading>
					</Box>
				</Container>
			</>
		)
	}

	return(
		<>
			{displayData()}
		</>
	)
}

export default TeamList;


/*

class TeamList extends Component{
	state = {
		National_Team: this.props.National_Team,
		teamListInfo:[],
		playerFilter: 'all',
		loaded: false
	}
	
	componentWillUpdate(){
		let url = null

		if (this.props.National_Team === "1"){
			url = 'national/?id='
		} else {
			url = 'team/teamlist/?id='
		}
		if (this.state.loaded !== true){
			Axios.get(url + this.props.Id)
			.then(transferinfoResponse => {
				this.setState({teamListInfo: transferinfoResponse.data.team, loaded:true});
			})
		}
	}

	componentDidUpdate(prevProps, prevState){
		let url = null

		if (this.props.National_Team === "1"){
			url = 'national/?id='
		} else {
			url = 'team/teamlist/?id='
		}
		if (this.state.loaded !== true){
		Axios.get(url + this.props.Id)
			.then(transferinfoResponse => {
				this.setState({teamListInfo: transferinfoResponse.data.team, loaded:true});
			})
		}
	}


	playerFilterHandler = ( action, value ) => {
        switch ( action ) {
            case 'all':
                this.setState( { playerFilter: 'all' } )
                break;
            case 'goalkeeper':
                this.setState( { playerFilter: 'Torwart' })
                break;
            case 'defender':
                this.setState( { playerFilter: 'Abwehr' })
                break;
            case 'midfielder':
                this.setState( { playerFilter: 'Mittelfeld' })
                break;
            case 'forward':
                this.setState( { playerFilter: 'Sturm' })
                break;
            default:
            	this.setState( { playerFilter: 'all' } )
        }
    }
	render(){
		let playerList = null

		if (this.state.playerFilter === 'all'){
			playerList = this.state.teamListInfo
							.sort((a, b) => a.firstname > b.firstname)
							.map(playerKey => {
							let PositionTranslate = playerKey.position
							let MainPositionTranslate = playerKey.mainPosition
							let Suspension = null
							let Injured = null
							let TransferListed = null
							if (playerKey.position === "Torwart"){
								PositionTranslate = "Goalkeeper"
							} else if (playerKey.position === "Abwehr"){
								PositionTranslate = "Defender"
							} else if (playerKey.position === "Mittelfeld"){
								PositionTranslate = "Midfielder"
							} else if (playerKey.position === "Sturm"){
								PositionTranslate = "Forward"
							}

							if (playerKey.mainPosition === "T"){
								MainPositionTranslate = "Goalkeeper"
							} else if (playerKey.mainPosition === "LV"){
								MainPositionTranslate = "Left Back"
							} else if (playerKey.mainPosition === "IV"){
								MainPositionTranslate = "Centre Back"
							} else if (playerKey.mainPosition === "RV"){
								MainPositionTranslate = "Right Back"
							} else if (playerKey.mainPosition === "LM"){
								MainPositionTranslate = "Left Midfield"
							} else if (playerKey.mainPosition === "DM"){
								MainPositionTranslate = "Defensive Midfield"
							} else if (playerKey.mainPosition === "ZM"){
								MainPositionTranslate = "Centre Midfield"
							} else if (playerKey.mainPosition === "RM"){
								MainPositionTranslate = "Right Midfield"
							} else if (playerKey.mainPosition === "OM"){
								MainPositionTranslate = "Attacking Midfield"
							} else if (playerKey.mainPosition === "LS"){
								MainPositionTranslate = "Left Forward"
							} else if (playerKey.mainPosition === "MS"){
								MainPositionTranslate = "Striker"
							} else if (playerKey.mainPosition === "RS"){
								MainPositionTranslate = "Right Forward"
							} else if (playerKey.mainPosition === ""){
								MainPositionTranslate = PositionTranslate
							}

							if ((playerKey.suspension > "0") || (playerKey.suspension_cups > "0")){
								Suspension = <span className="rc box box__alert__opacity heading__h4 border border__radius border__left border__right border__top border__bottom border__alert">
												{playerKey.suspension}
											</span>
							}
							if (playerKey.injured > "0"){
								Injured = <span className="injured"><span>{playerKey.injured}</span></span>
							}
							if (playerKey.transfer_listed === "1"){
								TransferListed = <div className="transfer-listed cell small-12 mt1 text-center box box__alt-dark-grey border border__left border__right border__top border__bottom border__medium border__dark-grey">
													Transfer Listed
												</div>
							}
							return (
								<Link to={'/player/' + playerKey.playerId} className="cell player box box__white box__shadow-hover border border__left border__right border__top border__bottom border__medium-grey mb2">
									<div key={playerKey.playerId}>
										<div className="PlayerImage">
											{playerKey.image ? <img src={playerKey.image} alt={this.props.FirstName}/> : <img src="https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg"  alt={this.props.FirstName}/>}
											<div className="bio ">
												<h3 className="heading heading__h3 heading__background-primary">Bio</h3>
											</div>
										</div>
										<div className="pl1 pr1 pt1 pb1">
											<h3 className="heading heading__h3">{playerKey.firstname} {playerKey.surname} {Suspension}{Injured}</h3>
											<h5 className="heading heading__h5 heading__medium-grey">{PositionTranslate}</h5>
											<h5 className="heading heading__h5 heading__medium-grey">{MainPositionTranslate}</h5>
											{TransferListed}
										</div>
									</div>
								</Link>
							)
						})
		} else {
			playerList = this.state.teamListInfo
							.filter(playerKey => playerKey.position === this.state.playerFilter)
							.sort((a, b) => a.firstname > b.firstname)
							.map(playerKey => {
							let PositionTranslate = playerKey.position
							let MainPositionTranslate = playerKey.mainPosition
							let Suspension = null
							let Injured = null
							let TransferListed = null
							if (playerKey.position === "Torwart"){
								PositionTranslate = "Goalkeeper"
							} else if (playerKey.position === "Abwehr"){
								PositionTranslate = "Defender"
							} else if (playerKey.position === "Mittelfeld"){
								PositionTranslate = "Midfielder"
							} else if (playerKey.position === "Sturm"){
								PositionTranslate = "Forward"
							}
							if (playerKey.mainPosition === "T"){
								MainPositionTranslate = "Goalkeeper"
							} else if (playerKey.mainPosition === "LV"){
								MainPositionTranslate = "Left Back"
							} else if (playerKey.mainPosition === "IV"){
								MainPositionTranslate = "Centre Back"
							} else if (playerKey.mainPosition === "RV"){
								MainPositionTranslate = "Right Back"
							} else if (playerKey.mainPosition === "LM"){
								MainPositionTranslate = "Left Midfield"
							} else if (playerKey.mainPosition === "DM"){
								MainPositionTranslate = "Defensive Midfield"
							} else if (playerKey.mainPosition === "ZM"){
								MainPositionTranslate = "Centre Midfield"
							} else if (playerKey.mainPosition === "RM"){
								MainPositionTranslate = "Right Midfield"
							} else if (playerKey.mainPosition === "OM"){
								MainPositionTranslate = "Attacking Midfield"
							} else if (playerKey.mainPosition === "LS"){
								MainPositionTranslate = "Left Forward"
							} else if (playerKey.mainPosition === "MS"){
								MainPositionTranslate = "Striker"
							} else if (playerKey.mainPosition === "RS"){
								MainPositionTranslate = "Right Forward"
							} else if (playerKey.mainPosition === ""){
								MainPositionTranslate = PositionTranslate
							}
							if ((playerKey.suspension > "0") || (playerKey.suspension_cups > "0")){
								Suspension = <span className="rc ">{playerKey.suspension}</span>
							}
							if (playerKey.injured > "0"){
								Injured = <span className="injured"><span>{playerKey.injured}</span></span>
							}
							if (playerKey.transfer_listed === "1"){
								TransferListed = <div className="transfer-listed cell small-12 mt1 text-center box box__alt-dark-grey border border__left border__right border__top border__bottom border__medium border__dark-grey">
													Transfer Listed
												</div>
							}
							return (
								<Link to={'/player/' + playerKey.playerId} className="cell player box box__white box__shadow-hover border border__left border__right border__top border__bottom border__medium-grey mb2">
									<div key={playerKey.playerId}>
										<div className="PlayerImage">
											{playerKey.image ? <img src={playerKey.image} alt={this.props.FirstName}/> : <img src="https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg"  alt={this.props.FirstName}/>}
											<div className="bio ">
												<h3 className="heading heading__h3 heading__background-primary">Bio</h3>
											</div>
										</div>
										<div className="pl1 pr1 pt1 pb1">
											<h3 className="heading heading__h3">{playerKey.firstname} {playerKey.surname} {Suspension}{Injured}</h3>
											<h5 className="heading heading__h5 heading__medium-grey">{PositionTranslate}</h5>
											<h5 className="heading heading__h5 heading__medium-grey">{MainPositionTranslate}</h5>
											{TransferListed}
										</div>
									</div>
								</Link>
							)
						})
		}

		return (
			<React.Fragment>
			<section>
				<div className="mb2 box box__dark-grey">
					<div className="grid-container">
						<ul className="menu">
							<li className="heading"><button className="button flex flex__content-align-vert-c" onClick={() => this.playerFilterHandler( 'all' )}><h5>All</h5></button></li>
							<li className="heading"><button className="button flex flex__content-align-vert-c" onClick={() => this.playerFilterHandler( 'goalkeeper' )}><h5>Goalkeepers</h5></button></li>
							<li className="heading"><button className="button flex flex__content-align-vert-c" onClick={() => this.playerFilterHandler( 'defender' )}><h5>Defenders</h5></button></li>
							<li className="heading"><button className="button flex flex__content-align-vert-c" onClick={() => this.playerFilterHandler( 'midfielder' )}><h5>Midfielders</h5></button></li>
							<li className="heading"><button className="button flex flex__content-align-vert-c" onClick={() => this.playerFilterHandler( 'forward' )}><h5>Strikers</h5></button></li>
						</ul>
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x large-up-4">
						{playerList}
					</div>
				</div>
			</section>
			</React.Fragment>
		)
	}
};

*/

