import React, {Component} from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import { Grid,
	GridItem,
	Heading,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Text,
	Divider,
	Container,
	Box
} from '@chakra-ui/react'


const Scorer = (props) => {
	
	const [data, setData] = React.useState({ 
		players: [],
		stats: [
			{
				type: 'goals',
				title: 'Top Goalscorer'
			},
			{
				type: 'assists',
				title: 'Most Assists'
			},
			{
				type: 'yc',
				title: 'Yellow Cards'
			},
			{
				type: 'rc',
				title: 'Red Cards'
			}
		]
	});

	const initialCall = () => {
		Promise.all([
			Axios.get('cup/scorers/?id=' + props.Id),
		])
		.then((playersResponse) => {
			setData({ 
				players: playersResponse[0].data.player,
				stats: [
					{
						type: 'goals',
						title: 'Top Goalscorer'
					},
					{
						type: 'assists',
						title: 'Most Assists'
					},
					{
						type: 'yc',
						title: 'Yellow Cards'
					},
					{
						type: 'rc',
						title: 'Red Cards'
					}
				]
			});
		}, (error) => {
		})
	}

	React.useEffect(() => {
		if (!props.Id) {
			return 
		}
		initialCall();
  	}, [props.Id]);

	const displayData = () => {
	    return data.players ? (
	      	<>
	      		<Grid
					templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', '2xl':'repeat(6, 1fr)' }}
					gap={6}
					bg='blackAlpha.900'
					padding={10}
				>
					{data.stats
						.map((dataKey, i) =>{
							let scorers = null

							let playerFilter = null


							if (props.TeamId != null){
								playerFilter = data.players.filter(playerKey => playerKey.teamid == props.TeamId)
							} else {
								playerFilter = data.players
							}
						return (
							<>
								<GridItem
									colSpan={{base: 1, md: 2, '2xl':6 }}
									w='100%'
									textAlign='center'
									color='white'
									textTransform='uppercase'
									key={dataKey.title}
								>
									<Heading>{ dataKey.title }</Heading>
								</GridItem>
								{playerFilter
									.sort(function (player1, player2) {
										let stats = dataKey.type
										if (parseInt(player1[stats]) < parseInt(player2[stats])) return 1;
										if (parseInt(player1[stats]) > parseInt(player2[stats])) return -1;
									})
									.slice(0, 6)
									.map((playerKey, i) =>{
										let stats = dataKey.type
										let img = ''
										let img2 = ''
										let imgHover = ''
										if (props.safeMode == 1){
											img = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											img2 = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											imgHover = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
										} else {
											img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
										}
										return(
											<GridItem key={i}
												w='100%'>
												<Link to={'/player/' + playerKey.id}>
													<Card 
														bgImage={img}
														bgSize="cover"
														bgPos='center'
														minH="550px"
														_hover={{bgImage:imgHover }}
														>
														<CardHeader
															bg='transparent'
														>
															<Text
																fontSize="7xl"
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																lineHeight={1}
															>
																{playerKey[stats]}
															</Text>
															<Text
																lineHeight={1}
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																textTransform="uppercase"
															>
																Goals
															</Text>
														</CardHeader>
														<CardBody
															display="flex"
															alignItems="flex-end"
														>
														<Text
																fontSize="3xl"
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																lineHeight={1}
																textTransform="uppercase"
															>
																{playerKey.firstname} {playerKey.surname}
															</Text>
														</CardBody>
														<Divider />
														<CardFooter>
															<Text
																lineHeight={1}
																bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																bgClip='text'
																textTransform="uppercase"
															>
																Player Profile
															</Text>
														</CardFooter>
													</Card>
												</Link>
											</GridItem>
										)
									})
								}
							</>
						)
					})}
				</Grid>
	        </>
	    ) : (
	    	<Container
					maxW='container.3xl'
					pt='75px;'
					pb='75px;'
				>
				<Box boxShadow='md' bg='white' w='100%' p={20}>
					<Heading size="2xl" textTransform="uppercase">No Stats Found</Heading>
				</Box>
			</Container>
	    );
	 }

	return(
		<>
			{displayData()}
        </>
	)
}

export default Scorer;


class Scorers extends Component {
	state = {
		id: this.props.Id,
		players: [],
		rendered: false,
	}

	componentDidUpdate(prevProps, prevState){ 
		if (this.state.rendered === false || this.props.Id !== prevProps.Id){
			Promise.all([
				Axios.get('cup/scorers/?id=' + this.props.Id)
			])
				.then(([playerResponse]) => {
					this.setState({players: playerResponse.data.player, rendered: true});
				}, (error) => {
				})
		}
	}

	static getDerivedStateFromProps(props, state){
		if (props.Id !== state.id) {
			return {
				id: props.Id
			}
	    }
	    return null
	}

	render(){

		let playerFilter = null

		if (this.props.TeamId != null){
			playerFilter = this.state.players.filter(playerKey => playerKey.teamid === this.props.TeamId)
		} else {
			playerFilter = this.state.players
		}

		let assists = null

		if (this.state.players.length > 0){

			assists = playerFilter
			.sort(({assists: previousAssists}, {assists: currentAssists}) => currentAssists - previousAssists)
			.slice(0, 6)
			.map((playerKey, i) =>{
				const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
									const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
									const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
				return (

					<GridItem key={i}
						w='100%'>
						<Link to={'/player/' + playerKey.id}>
							<Card 
								bgImage={img}
								bgSize="cover"
								bgPos='center'
								minH="550px"
								_hover={{bgImage:imgHover }}
								>
								<CardHeader
									bg='transparent'
								>
									<Text
										fontSize="7xl"
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										lineHeight={1}
									>
										{playerKey.goals}
									</Text>
									<Text
										lineHeight={1}
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										textTransform="uppercase"
									>
										Goals
									</Text>
								</CardHeader>
								<CardBody
									display="flex"
									alignItems="flex-end"
								>
								<Text
										fontSize="3xl"
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										lineHeight={1}
										textTransform="uppercase"
									>
										{playerKey.firstname} {playerKey.surname}
									</Text>
								</CardBody>
								<Divider />
								<CardFooter>
									<Text
										lineHeight={1}
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										textTransform="uppercase"
									>
										Player Profile
									</Text>
								</CardFooter>
							</Card>
						</Link>
					</GridItem>
				)
			});
		}

		let yc = null

		if (this.state.players.length > 0){

			yc = playerFilter
			.sort(({yc: previousYC}, {yc: currentYC}) => currentYC - previousYC)
			.slice(0, 6)
			.map((playerKey, i) =>{
				const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
				const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
				const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
				return (

					<GridItem key={i}
						w='100%'>
						<Link to={'/player/' + playerKey.id}>
							<Card 
								bgImage={img}
								bgSize="cover"
								bgPos='center'
								minH="550px"
								_hover={{bgImage:imgHover }}
								>
								<CardHeader
									bg='transparent'
								>
									<Text
										fontSize="7xl"
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										lineHeight={1}
									>
										{playerKey.goals}
									</Text>
									<Text
										lineHeight={1}
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										textTransform="uppercase"
									>
										Goals
									</Text>
								</CardHeader>
								<CardBody
									display="flex"
									alignItems="flex-end"
								>
								<Text
										fontSize="3xl"
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										lineHeight={1}
										textTransform="uppercase"
									>
										{playerKey.firstname} {playerKey.surname}
									</Text>
								</CardBody>
								<Divider />
								<CardFooter>
									<Text
										lineHeight={1}
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										textTransform="uppercase"
									>
										Player Profile
									</Text>
								</CardFooter>
							</Card>
						</Link>
					</GridItem>
				)
			});
		}

		let rc = null

		if (this.state.players.length > 0){

			rc = playerFilter
			.sort(({rc: previousRC}, {rc: currentRC}) => currentRC - previousRC)
			.slice(0, 6)
			.map((playerKey, i) =>{
				const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
									const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
									const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
				return (

					<GridItem key={i}
						w='100%'>
						<Link to={'/player/' + playerKey.id}>
							<Card 
								bgImage={img}
								bgSize="cover"
								bgPos='center'
								minH="550px"
								_hover={{bgImage:imgHover }}
								>
								<CardHeader
									bg='transparent'
								>
									<Text
										fontSize="7xl"
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										lineHeight={1}
									>
										{playerKey.goals}
									</Text>
									<Text
										lineHeight={1}
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										textTransform="uppercase"
									>
										Goals
									</Text>
								</CardHeader>
								<CardBody
									display="flex"
									alignItems="flex-end"
								>
								<Text
										fontSize="3xl"
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										lineHeight={1}
										textTransform="uppercase"
									>
										{playerKey.firstname} {playerKey.surname}
									</Text>
								</CardBody>
								<Divider />
								<CardFooter>
									<Text
										lineHeight={1}
										bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
										bgClip='text'
										textTransform="uppercase"
									>
										Player Profile
									</Text>
								</CardFooter>
							</Card>
						</Link>
					</GridItem>
				)
			});
		}
		return(
			<>
				<Grid
					templateColumns='repeat(6, 1fr)'
					gap={6}
					bg='blackAlpha.900'
					padding={10}
				>
					<GridItem
						colSpan={6}
						w='100%'
						textAlign='center'
						color='white'
						textTransform='uppercase'
					>
						<Heading>Top Goalscorers</Heading>
					</GridItem>
					{this.state.players
					.sort(({goals: previousGoals}, {goals: currentGoals}) => currentGoals - previousGoals)
					.slice(0, 6)
					.map((playerKey, i) =>{
						const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						return (

							<GridItem key={i}
								w='100%'>
								<Link to={'/player/' + playerKey.id}>
									<Card 
										bgImage={img}
										bgSize="cover"
										bgPos='center'
										minH="550px"
										_hover={{bgImage:imgHover }}
										>
										<CardHeader
											bg='transparent'
										>
											<Text
												fontSize="7xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
											>
												{playerKey.goals}
											</Text>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Goals
											</Text>
										</CardHeader>
										<CardBody
											display="flex"
											alignItems="flex-end"
										>
										<Text
												fontSize="3xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
												textTransform="uppercase"
											>
												{playerKey.firstname} {playerKey.surname}
											</Text>
										</CardBody>
										<Divider />
										<CardFooter>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Player Profile
											</Text>
										</CardFooter>
									</Card>
								</Link>
							</GridItem>
						)
					})};

					<GridItem
						colSpan={6}
						w='100%'
						textAlign='center'
						color='white'
						textTransform='uppercase'
					>
						<Heading>Most Assists</Heading>
					</GridItem>
					{this.state.players
					.sort(({assists: previousGoals}, {assists: currentGoals}) => currentGoals - previousGoals)
					.slice(0, 6)
					.map((playerKey, i) =>{
						const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						return (

							<GridItem key={i}
								w='100%'>
								<Link to={'/player/' + playerKey.id}>
									<Card 
										bgImage={img}
										bgSize="cover"
										bgPos='center'
										minH="550px"
										_hover={{bgImage:imgHover }}
										>
										<CardHeader
											bg='transparent'
										>
											<Text
												fontSize="7xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
											>
												{playerKey.assists}
											</Text>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Assists
											</Text>
										</CardHeader>
										<CardBody
											display="flex"
											alignItems="flex-end"
										>
										<Text
												fontSize="3xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
												textTransform="uppercase"
											>
												{playerKey.firstname} {playerKey.surname}
											</Text>
										</CardBody>
										<Divider />
										<CardFooter>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Player Profile
											</Text>
										</CardFooter>
									</Card>
								</Link>
							</GridItem>
						)
					})};

					<GridItem
						colSpan={6}
						w='100%'
						textAlign='center'
						color='white'
						textTransform='uppercase'
					>
						<Heading>Most Yellow Cards</Heading>
					</GridItem>
					{this.state.players
					.sort(({yc: previousGoals}, {yc: currentGoals}) => currentGoals - previousGoals)
					.slice(0, 6)
					.map((playerKey, i) =>{
						const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						return (

							<GridItem key={i}
								w='100%'>
								<Link to={'/player/' + playerKey.id}>
									<Card 
										bgImage={img}
										bgSize="cover"
										bgPos='center'
										minH="550px"
										_hover={{bgImage:imgHover }}
										>
										<CardHeader
											bg='transparent'
										>
											<Text
												fontSize="7xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
											>
												{playerKey.yc}
											</Text>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Yellow Cards
											</Text>
										</CardHeader>
										<CardBody
											display="flex"
											alignItems="flex-end"
										>
										<Text
												fontSize="3xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
												textTransform="uppercase"
											>
												{playerKey.firstname} {playerKey.surname}
											</Text>
										</CardBody>
										<Divider />
										<CardFooter>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Player Profile
											</Text>
										</CardFooter>
									</Card>
								</Link>
							</GridItem>
						)
					})};

					<GridItem
						colSpan={6}
						w='100%'
						textAlign='center'
						color='white'
						textTransform='uppercase'
					>
						<Heading>Most Red Cards</Heading>
					</GridItem>
					{this.state.players
					.sort(({rc: previousGoals}, {rc: currentGoals}) => currentGoals - previousGoals)
					.slice(0, 6)
					.map((playerKey, i) =>{
						const img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											const img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
											const imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
						return (

							<GridItem key={i}
								w='100%'>
								<Link to={'/player/' + playerKey.id}>
									<Card 
										bgImage={img}
										bgSize="cover"
										bgPos='center'
										minH="550px"
										_hover={{bgImage:imgHover }}
										>
										<CardHeader
											bg='transparent'
										>
											<Text
												fontSize="7xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
											>
												{playerKey.rc}
											</Text>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Red Cards
											</Text>
										</CardHeader>
										<CardBody
											display="flex"
											alignItems="flex-end"
										>
										<Text
												fontSize="3xl"
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												lineHeight={1}
												textTransform="uppercase"
											>
												{playerKey.firstname} {playerKey.surname}
											</Text>
										</CardBody>
										<Divider />
										<CardFooter>
											<Text
												lineHeight={1}
												bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
												bgClip='text'
												textTransform="uppercase"
											>
												Player Profile
											</Text>
										</CardFooter>
									</Card>
								</Link>
							</GridItem>
						)
					})};
				</Grid>
			</>
		)
	}
}