import React from 'react';
import { Link } from 'react-router-dom';

import {
	GridItem,
	Heading,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Text,
	Divider,
	Box
} from '@chakra-ui/react'

const Scorer = (props) => {
	
	const [data] = React.useState({ 
		stats: [
			{
				type: 'goals',
				title: 'Top Goalscorer'
			},
			{
				type: 'assists',
				title: 'Most Assists'
			}
		]
	});

	const displayData = () => {
		return props.Players ? (
			<>
				{ data.stats
					.map((dataKey, i) => {
						return(
							<>
								<Box key={dataKey.title}>
									<Heading
										textTransform='uppercase'
									>
										{ dataKey.title }
									</Heading>
									{props.Players
                                    	.sort((player1, player2) => (parseInt(player1[dataKey.type]) > parseInt(player2[dataKey.type])) ? -1 : 1)
										.slice(0, 1)
										.map((playerKey, i) =>{
											let stats = dataKey.type
											let img = ''
											let img2 = ''
											let imgHover = ''
											if (props.safeMode === 1){
												img = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
												img2 = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
												imgHover = 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											} else {
												img = playerKey.image ? playerKey.image : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
												img2 = playerKey.image2 ? playerKey.image2 : playerKey.image
												imgHover = playerKey.image ? img2 : 'https://websoccer.blue-phoenix.co.uk/img/No-Image.jpg'
											}
											return(
												<GridItem key={i}
													w='100%'>
													<Link to={'/player/' + playerKey.id}>
														<Card 
															bgImage={img}
															bgSize="cover"
															bgPos='center'
															minH="550px"
															_hover={{bgImage:imgHover }}
															>
															<CardHeader
																bg='transparent'
															>
																<Text
																	fontSize="7xl"
																	bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																	bgClip='text'
																	lineHeight={1}
																>
																	{playerKey[stats]}
																</Text>
																<Text
																	lineHeight={1}
																	bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																	bgClip='text'
																	textTransform="uppercase"
																>
																	{dataKey.type}
																</Text>
															</CardHeader>
															<CardBody
																display="flex"
																alignItems="flex-end"
															>
															<Text
																	fontSize="3xl"
																	bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																	bgClip='text'
																	lineHeight={1}
																	textTransform="uppercase"
																>
																	{playerKey.firstname} {playerKey.surname}
																</Text>
															</CardBody>
															<Divider />
															<CardFooter>
																<Text
																	lineHeight={1}
																	bgGradient={playerKey.image ? 'linear(to-br, whiteAlpha.600, whiteAlpha.900)' : 'linear(to-br, blue.600, blue.900)'}
																	bgClip='text'
																	textTransform="uppercase"
																>
																	Player Profile
																</Text>
															</CardFooter>
														</Card>
													</Link>
												</GridItem>
											)
										})
									}
								</Box>
							</>
						)
					})
				}
			</>
		) : (
	    	<>
			</>
		)
	 }

	return(
		<>
			{displayData()}
        </>
	)
}

export default Scorer;